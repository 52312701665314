import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"

import "bootstrap/dist/css/bootstrap.min.css"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PrenotaOra from "../components/prenotaOra"

import "../style/index.scss"

const IndexPage = ({ data }) => {
  return (
    <Layout page="">
      <Seo
        title="Supporto e Sostegno Psicologico"
        description="presentazione del dott. Jacopo Biraschi, dei suoi studi accademici e delle sue passioni, breve descrizione dei suoi ambiti di intervento e supporto psicologico quali, ad esempio, ansia, depressione e disturbi di personalit&agrave;, indirizzi dei due studi in cui riceve a Milano e Rho."
      />
      <div id="hero">
        <div id="title">
          <h1>Cambiare &egrave; possibile, stare meglio &egrave; possibile!</h1>
        </div>
        <div id="hero-pic">
          <Img
            className="pic"
            fluid={data.photo1.childImageSharp.fluid}
            loading="eager"
          />
        </div>
      </div>
      <div className="introduction">
        <div className="with-pic">
          <p>
            <strong>Forse sei qui perch&eacute;...</strong><br />
            In questo particolare momento della tua vita, ti stai ritrovando a fare i conti con difficolt&agrave; che fino a poco tempo fa non avevi.
            Azioni che fanno parte del tuo quotidiano e con le quali hai molta familiarit&agrave;, sono improvvisamente diventate difficili,
            pesanti… quasi insostenibili! &Egrave; tutto molto strano perch&eacute; non hai mai avuto problemi di questo tipo e quasi ti vergogni
            ad ammetterlo, per&ograve; adesso la situazione &eacute; esattamente questa e non puoi proprio farne a meno.
          </p>
          <div className="pic-wrapper">
            <Img
              className="bigger-size pic"
              fluid={data.photo2.childImageSharp.fluid}
              loading="eager"
            />
          </div>
        </div>
        <div className="with-pic">
          <div className="pic-wrapper">
            <Img
              className="bigger-size pic"
              fluid={data.photo3.childImageSharp.fluid}
              loading="eager"
            />
          </div>
          <p>
            <strong>La vita &egrave; troppo breve per essere infelici</strong><br />
            La cosa peggiore &egrave; che non ti senti pi&ugrave; te stess*, come se non sapessi pi&ugrave; fare tutte quelle cose che ti riuscivano cos&igrave; bene, nel lavoro, nel tuo gruppo di amici, con il tuo partner… &egrave; tutto sbagliato ora!
            Questa situazione ti sta incastrando, non si va pi&ugrave; avanti e si rischia addirittura di tornare indietro, dopo tutta la fatica e l’impegno che hai messo per arrivare l&igrave; dove sei ora… perch&eacute; sta succedendo? E cosa fare??
          </p>
        </div>
        <div className="with-pic">
          <p>
            <strong>Cambiare &egrave; possibile!</strong><br />
            Non tutto &egrave; perduto per&ograve;, la situazione non &egrave; irreversibile e cambiare &egrave; possibile! Anche solo il fatto che tu sia arrivat* qui a leggere queste parole, significa che hai preso coscienza di cosa stia andando storto; magari non hai ancora ben chiaro quali siano i motivi per i quali adesso ti trovi in questa situazione, ma di certo sai che non &egrave; quello che vuoi e hai deciso di fare qualcosa!
          </p>
          <div className="pic-wrapper">
            <Img
              className="bigger-size pic"
              fluid={data.photo4.childImageSharp.fluid}
              loading="eager"
            />
          </div>
        </div>
        <div>
          <p>
            <strong>Immagina qualcosa di diverso...</strong><br />
            Come sarebbe la tua vita se riuscissi a sentirti di nuovo te stess*? Se potessi di nuovo prendere in mano la tua vita e decidere cosa farne, anzich&eacute; essere schiav* delle difficolt&agrave; che stai affrontando ora? Di certo potresti provare di nuovo la soddisfazione di riuscire nel tuo lavoro, di essere presente per i tuoi cari e di fare ci&ograve; che pi&ugrave; ti piace senza sentirti paralizzat*. La psicoterapia &egrave; ci&ograve; di cui hai bisogno per tornare ad essere la persona che vuoi essere, a realizzare a pieno il tuo potenziale e godere della tua vita in modo sereno. Tramite la mia formazione specifica posso
            insegnarti a conoscere davvero il circolo vizioso nel quale sei incastrat*, comprenderlo e soprattutto capire come si &egrave; strutturato nel tempo e perch&eacute;. Il lavoro con ogni mio paziente parte proprio da qui: dal blocco che si vive nel presente e questo &egrave; vero anche per te, individueremo il blocco e capiremo come farti ripartire! Cambiare &egrave; possibile, fai un altro passo verso la persona che vuoi essere veramente prenotando il tuo primo colloquio!
          </p>
        </div>
      </div>
      <div className="introduction">
        <Img
          className="pic"
          fluid={data.photo5.childImageSharp.fluid}
          loading="eager"
        />
      </div>
      <div className="introduction extra-margin">
        <div className="with-pic">
          <div className="pic-wrapper">
            <Img
              className="bigger-size pic"
              fluid={data.heroPic.childImageSharp.fluid}
              loading="eager"
            />
          </div>
          <p>
            Ciao, sono Jacopo Biraschi<br />
            Sono uno psicologo clinico specializzato in psicoterapia cognitivo-comportamentale.<br />
            Ti invito a scoprire i servizi che offro e a contattarmi per parlarmi di te.
          </p>
        </div>
      </div>
      <div className="call-to-action">
        <PrenotaOra />
      </div>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    photo1: file(relativePath: { eq: "lina-trochez-ktPKyUs3Qjs-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photo2: file(relativePath: { eq: "noah-silliman-gzhyKEo_cbU-unsplash_quad.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photo3: file(relativePath: { eq: "dev-asangbam-_sh9vkVbVgo-unsplash_quad.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photo4: file(relativePath: { eq: "pablo-heimplatz-EAvS-4KnGrk-unsplash_quad.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    photo5: file(relativePath: { eq: "marc-najera-Cj2d2IUEPDM-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heroPic: file(relativePath: { eq: "dott-jacopo-biraschi-psicologo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rhoMaps: file(relativePath: { eq: "green-chameleon-s9CC2SKySJM-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    milanMaps: file(relativePath: { eq: "samantha-gades-BlIhVfXbi9s-unsplash.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    womanTablet: file(
      relativePath: { eq: "brooke-cagle-uWVWQ8gF8PE-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1200, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
